import React from "react";
import { Image } from "mui-image";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ReactAudioPlayer from "react-audio-player";
import { Avatar, Typography, IconButton, Link } from "@mui/material";

import { VStack } from "../components/v-stack.component";
import { HStack } from "../components/h-stack.component";
import { Web3Context } from "../services/web3.context";
import DefaultTrackImage from "../assets/default_track_image.png";

export const ListenScreen = () => {
  const [song, setSong] = React.useState({});
  const [songCount, setSongCount] = React.useState(0);
  const [chosenSongIndex, setChosenSongIndex] = React.useState(0);
  const { contract, isLoading, contractAddress } =
    React.useContext(Web3Context);

  React.useEffect(() => {
    if (songCount > 0) {
      contract.methods
        .tracks(Math.abs(chosenSongIndex % songCount))
        .call()
        .then((song) => setSong(song));
    }
  }, [songCount, chosenSongIndex, contract?.methods]);

  React.useEffect(() => {
    if (!isLoading) {
      contract.methods
        .getTrackCount()
        .call()
        .then((count) => setSongCount(count));
    }
  }, [isLoading, contract?.methods]);

  return (
    <VStack spacing={1} alignItems="center">
      <VStack alignItems="center" spacing={1}>
        <HStack spacing={1} alignItems="center" justifyContent="flex-start">
          <Avatar
            src={
              song?.artist_profile_image
                ? song?.artist_profile_image
                : DefaultTrackImage
            }
          />
          <VStack spacing={0}>
            <Typography variant="body" fontWeight={"bold"}>
              {song?.release_title}
            </Typography>
            <HStack spacing={0}>
              <Typography variant="caption">{song?.title} by</Typography>
              <Typography variant="caption" fontWeight={"bold"}>
                &nbsp;
                {song?.artist_name}
              </Typography>
            </HStack>
          </VStack>
        </HStack>
        <Link
          variant="caption"
          color="primary"
          underline="none"
          target={"_blank"}
          href={`https://testnets.opensea.io/assets/goerli/${contractAddress}/${chosenSongIndex}/`}
        >
          {contractAddress ? contractAddress.slice(0, 16) : ""}...
        </Link>
      </VStack>

      <HStack>
        <IconButton
          disableRipple
          disabled={isLoading}
          onClick={() => setChosenSongIndex((chosenSongIndex - 1) % songCount)}
        >
          <ChevronLeft />
        </IconButton>
        <Image
          src={
            song?.release_cover_image
              ? song?.release_cover_image
              : DefaultTrackImage
          }
          alt={song?.track_title}
          style={{
            width: 400,
            height: 400,
            maxWidth: "75%",
            maxHeight: "75%",
            borderRadius: "10%",
          }}
        />
        <IconButton
          disableRipple
          disabled={isLoading}
          onClick={() => setChosenSongIndex((chosenSongIndex + 1) % songCount)}
        >
          <ChevronRight />
        </IconButton>
      </HStack>

      <HStack alignItems="center" spacing={2}>
        <ReactAudioPlayer src={song?.audio} controls />
      </HStack>
    </VStack>
  );
};

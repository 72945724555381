import Web3 from "web3";
import React from "react";
import { useMetaMask } from "metamask-react";
// internal
import ContractJSON from "../assets/MusicFactory.json";

export const Web3Context = React.createContext();

export const Web3ContextProvider = ({ children }) => {
  const contractAddress = "0x4151F24Ef60B48F65C4D85adFd89d1CBf30E32a5";
  const { ethereum } = useMetaMask();
  const [web3, setWeb3] = React.useState(null);
  const [contract, setContract] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (ethereum) {
      setIsLoading(true);
      setWeb3(new Web3(ethereum));
    } else {
      setWeb3(
        new Web3("https://goerli.infura.io/v3/8b9d9d837f8047d59f073a52f4535563")
      );
    }
  }, [ethereum]);

  React.useEffect(() => {
    if (web3?.eth) {
      setContract(new web3.eth.Contract(ContractJSON.abi, contractAddress));
      setIsLoading(false);
    }
  }, [web3]);

  return (
    <Web3Context.Provider
      value={{
        web3,
        contract,
        isLoading,
        contractAddress,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

import React from "react";
import { useMetaMask } from "metamask-react";
import { Web3Context } from "../services/web3.context";
import { VStack } from "../components/v-stack.component";
import { ReleaseCard } from "../components/release-card.component";

export const ReleaseListScreen = () => {
  const { account } = useMetaMask();
  const [songIds, setSongIds] = React.useState([]);
  const { contract, isLoading } = React.useContext(Web3Context);

  React.useEffect(() => {
    if (!isLoading && account) {
      contract.methods
        .getTracksByOwner(account)
        .call()
        .then((songIds) => {
          setSongIds(songIds);
        });
    }
  }, [isLoading, contract?.methods, songIds, account]);

  return (
    <VStack flex={1} spacing={5} alignItems="center">
      {songIds.map((songId) => (
        <ReleaseCard id={songId} key={songId} />
      ))}
    </VStack>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import { MetaMaskProvider } from "metamask-react";
// internal
import "./index.css";
import { router } from "./router";
import { Web3ContextProvider } from "./services/web3.context";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MetaMaskProvider>
      <Web3ContextProvider>
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </Web3ContextProvider>
    </MetaMaskProvider>
  </React.StrictMode>
);

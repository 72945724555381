import React from "react";
import { useMetaMask } from "metamask-react";
import { CheckCircle } from "@mui/icons-material";
import { CircularProgress, Button, Tooltip, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Stack } from "@mui/system";
import { HStack } from "./h-stack.component";

export const Wallet = () => {
  const { status, connect, account } = useMetaMask();

  if (status === "unavailable")
    return (
      <Button
        href="https://metamask.io/download/"
        target="_blank"
        variant="contained"
        color="primary"
        size="small"
      >
        Download MetaMask
      </Button>
    );

  if (status === "notConnected")
    return (
      <Button
        onClick={connect}
        variant="contained"
        color="secondary"
        size="small"
      >
        <HStack spacing={1}>
          <Typography>Connect</Typography>
          <AccountBalanceWalletIcon />
        </HStack>
      </Button>
    );

  if (status === "connecting" || status === "initializing")
    return <CircularProgress />;

  if (status === "connected")
    return (
      <Button
        onClick={connect}
        variant="contained"
        color="success"
        size="small"
      >
        <HStack spacing={1}>
          <Typography>Connected</Typography>
          <CheckCircle />
        </HStack>
      </Button>
    );

  return null;
};

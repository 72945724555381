import React from "react";

import Image from "mui-image";
import { ThemeProvider } from "@mui/system";
import { Outlet } from "react-router-dom";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { createTheme, CssBaseline, IconButton, Tooltip } from "@mui/material";
// icons
import PublishIcon from "@mui/icons-material/Publish";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
// internal
import { Wallet } from "../components/wallet.component";
import { HStack } from "../components/h-stack.component";
import { VStack } from "../components/v-stack.component";
import biddzBlackLogo from "../assets/biddz_logo_black.png";
import biddzWhiteLogo from "../assets/biddz_logo_white.png";

export const TemplateScreen = () => {
  const colorModeCache = localStorage.getItem("colorModeCache");
  const [colorMode, setColorMode] = React.useState(
    colorModeCache ? colorModeCache : "light"
  );

  const toggleAndCacheColorMode = () => {
    const colorModeNew = colorMode === "light" ? "dark" : "light";
    localStorage.setItem("colorModeCache", colorModeNew);
    setColorMode(colorModeNew);
  };

  const theme = createTheme({
    palette: {
      mode: colorMode,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HStack justifyContent={"center"} flex={1}>
        <VStack spacing={5} flex={1} p={2} maxWidth={800}>
          <IconButton size="small" href="/" disableRipple>
            <Image
              style={{
                objectFit: "contain",
                maxWidth: 150,
              }}
              alt="Biddz Logo"
              duration={0}
              src={colorMode === "light" ? biddzBlackLogo : biddzWhiteLogo}
            />
          </IconButton>
          <HStack
            style={{
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton size="small" href="/">
              <Tooltip title="Listen">
                <HeadphonesIcon />
              </Tooltip>
            </IconButton>

            <IconButton size="small" href="/releases/create">
              <Tooltip title="New Release">
                <PublishIcon />
              </Tooltip>
            </IconButton>
            <IconButton size="small" href="/releases/">
              <Tooltip title="My Releases">
                <LibraryMusicIcon />
              </Tooltip>
            </IconButton>
            <Wallet />
            <IconButton onClick={toggleAndCacheColorMode} color="inherit">
              {colorMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </HStack>
          <Outlet />
        </VStack>
      </HStack>
    </ThemeProvider>
  );
};

import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
} from "@mui/material";
import { VStack } from "./v-stack.component";
import { Web3Context } from "../services/web3.context";
import ReactAudioPlayer from "react-audio-player";

export const ReleaseCard = ({ id }) => {
  const [song, setSong] = React.useState({});
  const { contract, isLoading } = React.useContext(Web3Context);

  React.useEffect(() => {
    if (!isLoading && id) {
      contract.methods
        .tracks(id)
        .call()
        .then((resp) => setSong(resp));
    }
  }, [id, isLoading, contract?.methods]);

  return (
    <Card>
      <VStack justifyContent="center" alignItems="center">
        <CardHeader
          avatar={<Avatar src={song?.artist_profile_image} />}
          title={song?.release_title}
          subheader={song?.title}
        />
        <CardMedia
          component="img"
          src={song?.release_cover_image}
          style={{
            maxWidth: "75%",
            borderRadius: "10%",
          }}
        />
        <CardContent>
          <ReactAudioPlayer src={song?.audio} controls />
        </CardContent>
      </VStack>
    </Card>
  );
};

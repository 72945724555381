import { createBrowserRouter } from "react-router-dom";
// internal screens
import { ListenScreen } from "./screens/listen.screen";
import { TemplateScreen } from "./screens/template.screen";
import { ReleaseListScreen } from "./screens/release-list.screen";
import { ReleaseCreateScreen } from "./screens/release-create.screen";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <TemplateScreen />,
    children: [
      {
        path: "",
        element: <ListenScreen />,
      },
      {
        path: "releases/create/",
        element: <ReleaseCreateScreen />,
      },
      {
        path: "releases/",
        element: <ReleaseListScreen />,
      },
    ],
  },
]);

import React from "react";
import { useSnackbar } from "notistack";
import { useMetaMask } from "metamask-react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { VStack } from "../components/v-stack.component";
import { Web3Context } from "../services/web3.context";

export const ReleaseCreateScreen = () => {
  const { account } = useMetaMask();
  const { contract } = React.useContext(Web3Context);
  const [isLoading, setIsLoading] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    contract.methods
      .createTrack(
        formData.get("title"),
        formData.get("audio"),
        formData.get("release_title"),
        formData.get("release_cover_image"),
        formData.get("artist_name"),
        formData.get("artist_profile_image")
      )
      .send({ from: account })
      .on("receipt", () => {
        enqueueSnackbar("Release was minted successfully!", {
          variant: "success",
        });
        event.target.reset();
        setIsLoading(false);
      })
      .on("error", (error) => {
        enqueueSnackbar(
          "Operation failed... Please fix values and try again later.",
          {
            variant: "error",
          }
        );
        event.target.reset();
        setIsLoading(false);
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} name="release_form">
      <VStack spacing={3}>
        <FormControl required>
          <TextField
            disabled={isLoading || !account}
            required
            id="artist_name"
            name="artist_name"
            aria-describedby="artist_name_text"
            label="Artist Name"
          />
          <FormHelperText id="artist_name_text">
            The name of the artist should match their name on DSPs (Spotify,
            Apple Music, etc.)
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            disabled={isLoading || !account}
            required
            id="artist_profile_image"
            name="artist_profile_image"
            aria-describedby="artist_profile_image_text"
            label="Artist Profile Image URL"
          />
          <FormHelperText id="artist_profile_image_text">
            Please upload your profile image to a website of your choice and
            then paste the URL in the field above. We recommend using IPFS or
            arweave.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            disabled={isLoading || !account}
            required
            id="release_title"
            name="release_title"
            aria-describedby="release_title_text"
            label="Release Title"
          />
          <FormHelperText id="release_title_text">
            Please choose the title of the release.
          </FormHelperText>
        </FormControl>

        <FormControl>
          <TextField
            disabled={isLoading || !account}
            required
            id="release_cover_image"
            name="release_cover_image"
            aria-describedby="release_cover_image_text"
            label="Release Cover Image URL"
          />
          <FormHelperText id="release_cover_image_text">
            Please upload your release cover image to a website of your choice
            and then paste the URL in the field above. We recommend using IPFS
            or arweave.{" "}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            disabled={isLoading || !account}
            required
            id="title"
            name="title"
            aria-describedby="title_text"
            label="Track Title"
          />
          <FormHelperText id="title_text">
            Please choose the title of the track.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            disabled={isLoading || !account}
            required
            id="audio"
            name="audio"
            aria-describedby="audio_text"
            label="Audio File URL"
          />
          <FormHelperText id="audio_text">
            Please upload your track audio file (.wav) to a website of your
            choice and then paste the URL in the field above. We recommend using
            IPFS or arweave.{" "}
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || !account}
          p={1}
        >
          {isLoading ? <CircularProgress color="secondary" /> : "Mint Release"}
        </Button>
      </VStack>
    </Box>
  );
};
